//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.keyvisual {
    height: 400px;
    width: 100%;
    background-color: black;
    overflow: hidden;
    position: relative;
}

.keyvisual img {
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
}

#upper-nav::before {
    content: "";
    display: block;
    width: 100%;
    height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom, #c6c8c9 0%, #fff 100%);
    z-index: -1;
}

#upper-nav .nav-link {
    color: white;
    margin: auto auto;
    padding-left: 17px;
    padding-right: 17px;
}

#upper-nav .nav-item:hover {
    background-color: rgb(0, 114, 134);
    transition: background-color ease-out 0.5s;
}

#upper-nav {
    padding-left: 50px;
    padding-right: 50px;
    font-size: 12px;
}

#lower-nav {
    background-color: white;
}

.nav-item {
    display: flex;
}

.navbar {
    padding-top: 0;
    padding-bottom: 0;
}

.external-link {
    font-weight: 700;
    padding: 8px 17px !important;
}

@media only screen and (min-width: 576px) {
    #upper-nav .with-border {
        border-left: 1px solid #007286;
    }
}

@media only screen and (min-width: 992px) {
    #upper-nav {
        height: 45px;
    }

    #lower-nav {
        height: 72px;
        padding-left: 50px;
        padding-right: 50px;
    }

    #lower-nav .nav-link {
        margin: auto auto;
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
        font-weight: 500;
        font-size: 16px;
        color: rgb(85, 85, 85);
        border-top: 4px solid #fff;
    }

    #lower-nav .nav-link:hover {
        border-top: 4px solid #008aa2;
        transition: border-color ease-out 0.5s;
    }

    #lower-nav img {
        margin: auto auto;
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
.bd-callout {
    padding: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    border: 1px solid #e9ecef;
    border-left-width: 0.25rem;
    border-radius: 0.25rem;
}
.bd-callout h4 {
    margin-bottom: 0.25rem;
}
.bd-callout p:last-child {
    margin-bottom: 0;
}
.bd-callout code {
    border-radius: 0.25rem;
}
.bd-callout + .bd-callout {
    margin-top: -0.25rem;
}
.bd-callout-info {
    border-left-color: #5bc0de;
}
.bd-callout-warning {
    border-left-color: #f0ad4e;
}
.bd-callout-danger {
    border-left-color: #d9534f;
}
.bd-example {
    border: solid #dee2e6;
    border-radius: 0.25rem;
    border-width: 1px;
}
